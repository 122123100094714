import { global } from './global'

let _baseurl = global.baseApiUrl + 'dashboards/'

export default {
  getChart(param) {
    return global.axios.post(_baseurl + 'get-chart', param)
  },
  getChartCalidadVenta(param) {
    return global.axios.post(_baseurl + 'get-chart-calidad', param)
  },
  getChartEmpleado(param) {
    return global.axios.post(_baseurl + 'get-chart-empleado', param)
  },
  getChartYtd(param) {
    return global.axios.post(_baseurl + 'get-chart-ytd', param)
  },
  getChartEmpleadoIndividual(param) {
    return global.axios.post(_baseurl + 'get-chart-empleado-invididual', param)
  },
  getChartComisionCanalInterno(param) {
    return global.axios.post(_baseurl + 'get-chart-comision-canal-interno', param)
  },
  getChartComisionPorCanales(param) {
    return global.axios.post(_baseurl + 'get-chart-comision-por-canales', param)
  },
  getChartComisionesPorSegmentos(param) {
    return global.axios.post(_baseurl + 'get-chart-comision-por-segmentos', param)
  },
  getChartComisionPorGruposComision(param) {
    return global.axios.post(_baseurl + 'get-chart-comision-por-grupos-comision', param)
  },
  getChartComisionPorAsociados(param) {
    return global.axios.post(_baseurl + 'get-chart-comision-por-asociados', param)
  },
  getChartComisionesPorEmpleados(param) {
    return global.axios.post(_baseurl + 'get-chart-comision-por-empleados', param)
  },
  getChartEmpleadoIncentivoOptions(param) {
    return global.axios.post(_baseurl + 'get-chart-empleado-incentivo', param)
  },
  getChartEvolucionPagoComisionesNeta(param) {
    return global.axios.post(_baseurl + 'get-chart-evolucion-pago-comisiones-neta', param)
  },
  getGetChartComparacionComisionesGeneradas(param) {
    return global.axios.post(_baseurl + 'get-chart-comparacion-comisiones-generadas', param)
  },
  getGetChartLiquidez(param) {
    return global.axios.post(_baseurl + 'get-chart-liquidez', param)
  },
  getGetChartReclamacion(param) {
    return global.axios.post(_baseurl + 'get-chart-reclamacion', param)
  },
  getProductoTipos() {
    return global.axios.get(_baseurl + 'get-productos-tipos')
  }

}
